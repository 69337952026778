.btn-off {
    background-color: green;
    color: white;
    padding: 8px 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-on {
    background-color: rgb(255, 54, 54);
    color: white;
    padding: 8px 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }