.container {
    margin: 20px auto;
    max-width: 1000px;
    text-align: center;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  th, td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  
  tr:hover.not_deleted{
    background-color: #f1f1f1;
  }
  
  .loading, .error {
    font-size: 1.5em;
    color: #333;
  }
  
  .loading {
    color: #007bff;
  }
  
  .error {
    color: #ff4d4d;
  }
  .deleted {
    background-color: #ffcccc; /* Light red color */
  }
  
  
  