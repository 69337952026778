/* Styles for NoAccess component */
.no-access-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
  .no-access-content {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .no-access-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .no-access-content p {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .no-access-content button {
    padding: 10px 15px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.2s ease;
  }
  
  .no-access-content button:hover {
    background-color: #0056b3;
  }
  