.filterContainer {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .filterContainer label {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .filterContainer input[type="date"] {
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .tableContainer {
    margin: 20px;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .tableContainer th, .tableContainer td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .tableContainer th {
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
  }
  
  .tableContainer tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .tableContainer tr:hover {
    background-color: #f1f1f1;
  }
  
  .tableContainer th, .tableContainer td {
    text-align: left;
    padding: 12px;
  }
  