/* CompetitionTeams.css */

.competition-teams-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.competition-teams-paper {
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 16px;
}

.competition-teams-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.competition-teams-data-grid .MuiDataGrid-root {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.competition-teams-data-grid .MuiDataGrid-cell {
    border-bottom: 1px solid #e0e0e0;
}

.competition-teams-data-grid .MuiDataGrid-columnHeaders {
    background-color: #aac2da;
    font-size: 1rem;
}
