/* .add-competition-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
} */

.toggle-button {
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.toggle-button:hover {
    background-color: #0056b3;
}

.date-filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.date-filters label {
    font-weight: bold;
    margin-right: 10px;
}

.date-filters input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.loading-message {
    text-align: center;
    font-size: 18px;
    color: #333;
}

.error-message {
    text-align: center;
    font-size: 18px;
    color: red;
}

.competition-list {
    list-style-type: none;
    padding: 0;
}

.competition-item {
    padding: 10px;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.competition-item:hover {
    background-color: #f1f1f1;
}
.pagination button.active {
    background-color: #c1b14a;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border: none;

}
.pagination button.not-active {
    background-color: #f1f1f1;
    color: #333;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 5px;
    border: solid 1px #c1b14a;
}

